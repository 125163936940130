var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable radix */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useParams, useNavigate } from 'react-router-dom';
import { fetchMinigames } from '../Assets/Data/minigamesService';
import React, { useContext, useEffect, useState } from 'react';
import { SenderReceiverContext } from '../Network/SenderReceiverContext';
import { GameType, ALL_IMAGE_MODELS_ENUMERABLE } from '../../../Backend/shared_types/Types';
import { TextGenerator } from '../Network/TextGenerator';
import TypewriterText from '../Utils/Components/TypewriterText';
var defaultGameSettings = {
    textToImageModel: 'Stable Diffusion 1.5',
    generationTime: 60,
    votingTime: 60,
    themeCreationTime: 60,
    themeSelectionTime: 30,
    generationCount: 3,
};
var hostSystemMessage = "You are the host of a minigame begrudgingly welcoming players as they join the lobby.\nYou are a robot that is rude and ornery. You are annoyed that so many people\nkeep making you do your job as host. You are also a little bit evil and want\nplayers to have a bad time. Your greetings should be one sentence in length, with no period at the end.";
var hostPromptMessage = 'I join the lobby of this casual image generation based minigame and you greet me.';
function Lobby() {
    var _this = this;
    var _a, _b, _c;
    var senderReceiver = useContext(SenderReceiverContext);
    var navigate = useNavigate();
    var _d = useParams(), gameType = _d.gameType, gameCode = _d.gameCode;
    var _e = useState({}), minigame = _e[0], setMinigame = _e[1];
    var _f = useState((_b = (_a = senderReceiver.activeGame) === null || _a === void 0 ? void 0 : _a.players) !== null && _b !== void 0 ? _b : []), players = _f[0], setPlayers = _f[1];
    var _g = useState(''), userName = _g[0], setUserName = _g[1];
    var _h = useState(''), hostMessage = _h[0], setHostMessage = _h[1];
    var _j = useState(defaultGameSettings), gameSettings = _j[0], setGameSettings = _j[1];
    senderReceiver.on('PlayerListUpdated', handlePlayerListUpdated);
    senderReceiver.on('GameStarted', handleGameStarted);
    function handlePlayerListUpdated(players) {
        if (!senderReceiver.activeGame) {
            console.error('Active game is null');
            return;
        }
        console.log("Player list updated; ".concat(JSON.stringify(players)));
        senderReceiver.activeGame.players = players;
        var newLocalPlayer = players.find(function (player) { return player.id === senderReceiver.localPlayer.id; }); // HACK
        senderReceiver.localPlayer = newLocalPlayer;
        setPlayers(players);
    }
    function handleGameStarted(chosenSettings) {
        if (!senderReceiver.activeGame || !gameCode) {
            console.error("Active game with join code ".concat(gameCode !== null && gameCode !== void 0 ? gameCode : 'UNDEFINED', " is invalid"));
            return;
        }
        console.log('Starting game.');
        cleanupListeners();
        navigate("".concat(minigame.route, "/").concat(gameCode), { state: chosenSettings !== null && chosenSettings !== void 0 ? chosenSettings : defaultGameSettings });
    }
    function cleanupListeners() {
        senderReceiver.off('PlayerListUpdated', handlePlayerListUpdated);
    }
    useEffect(function () {
        var textGenerator = new TextGenerator(senderReceiver, 'gpt-3.5-turbo');
        var loadMinigames = function () { return __awaiter(_this, void 0, void 0, function () {
            var minigames, parsedGameType, minigameData, promptMessages;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchMinigames()];
                    case 1:
                        minigames = _a.sent();
                        parsedGameType = GameType[gameType];
                        minigameData = minigames.find(function (minigame) { return parsedGameType === minigame.gameType; });
                        if (!minigameData) {
                            console.error("Minigame with id ".concat(gameType !== null && gameType !== void 0 ? gameType : 'UNDEFINED', " not found"));
                            return [2 /*return*/];
                        }
                        promptMessages = [
                            { role: 'system', content: hostSystemMessage },
                            { role: 'user', content: hostPromptMessage },
                        ];
                        textGenerator.groupGenerateText('LobbyGreeting', promptMessages)
                            .then(function (generatedText) {
                            setHostMessage(generatedText);
                        })
                            .catch(function (err) {
                            console.error(err);
                        });
                        setMinigame(minigameData);
                        return [2 /*return*/];
                }
            });
        }); };
        void loadMinigames();
        return function () {
            console.log('Lobby unmounted');
        };
    }, [gameType]);
    function handleInputNameChange(e) {
        setUserName(e.target.value);
    }
    function handleNameSubmit(e) {
        e.preventDefault();
        senderReceiver.emit('SetName', userName);
    }
    function startGame() {
        senderReceiver.emit('StartGame', gameSettings);
    }
    var gameReady = function () {
        if (players.length >= minigame.minPlayers && players.length <= minigame.maxPlayers) {
            return players.every(function (player) { return player.name; });
        }
        return false;
    };
    return (_jsxs("div", { children: [hostMessage && _jsx("h1", { children: _jsx(TypewriterText, { children: hostMessage }) }), _jsxs("h2", { children: ["Game: ", minigame === null || minigame === void 0 ? void 0 : minigame.name, " --- Join Code: ", gameCode, " "] }), _jsx("p", { children: "Players:" }), senderReceiver.localPlayer.name
                ? _jsxs("p", { children: ["You: ", senderReceiver.localPlayer.name] }, senderReceiver.localPlayer.id)
                : _jsxs("form", { onSubmit: handleNameSubmit, style: { display: 'flex', alignItems: 'center' }, children: [_jsx("p", { style: { marginRight: '10px' }, children: "You: ???" }, senderReceiver.localPlayer.id), _jsx("input", { type: 'text', placeholder: 'Your name here', value: userName, onChange: handleInputNameChange, style: { marginRight: '10px' } }), _jsx("button", { type: 'submit', children: "Submit" })] }), players.filter(function (player) { return player.id !== senderReceiver.localPlayer.id; })
                .map(function (player) { return (_jsx("p", { children: player.name ? player.name : '???' }, player.id)); }), ((_c = senderReceiver.activeGame) === null || _c === void 0 ? void 0 : _c.host.id) === senderReceiver.localPlayer.id
                && (_jsxs(_Fragment, { children: [_jsx("button", { onClick: startGame, disabled: !gameReady(), style: { marginTop: '10px' }, children: "Start Game" }), _jsx("p", { children: " " }), _jsx("p", { children: " " }), _jsxs("div", { children: [_jsx("label", { htmlFor: 'textToImageModel', children: "Image Model:" }), _jsx("select", { id: 'textToImageModel', value: gameSettings.textToImageModel, onChange: function (e) {
                                        var model = e.target.value;
                                        var generationCount = gameSettings.generationCount;
                                        if (model === 'Control Net') {
                                            generationCount = 1;
                                        }
                                        setGameSettings(__assign(__assign({}, gameSettings), { textToImageModel: model, generationCount: generationCount }));
                                    }, children: Object.values(ALL_IMAGE_MODELS_ENUMERABLE).map(function (value) { return (_jsx("option", { value: value, children: value }, value)); }) })] }), _jsxs("div", { children: [_jsx("label", { htmlFor: 'generationTime', children: "Generation Time:" }), _jsx("input", { type: 'number', min: '0', id: 'generationTime', value: gameSettings.generationTime, onChange: function (e) {
                                        var _a;
                                        var value = (_a = parseInt(e.target.value)) !== null && _a !== void 0 ? _a : 0;
                                        value = value === 0 ? undefined : value;
                                        setGameSettings(__assign(__assign({}, gameSettings), { generationTime: value }));
                                    } })] }), _jsxs("div", { children: [_jsx("label", { htmlFor: 'votingTime', children: "Voting Time:" }), _jsx("input", { type: 'number', min: '0', id: 'votingTime', value: gameSettings.votingTime, onChange: function (e) {
                                        var _a;
                                        var value = (_a = parseInt(e.target.value)) !== null && _a !== void 0 ? _a : 0;
                                        value = value === 0 ? undefined : value;
                                        setGameSettings(__assign(__assign({}, gameSettings), { votingTime: value }));
                                    } })] }), _jsxs("div", { children: [_jsx("label", { htmlFor: 'themeCreationTime', children: "Theme Creation Time:" }), _jsx("input", { type: 'number', min: '0', id: 'themeCreationTime', value: gameSettings.themeCreationTime, onChange: function (e) {
                                        var _a;
                                        var value = (_a = parseInt(e.target.value)) !== null && _a !== void 0 ? _a : 0;
                                        value = value === 0 ? undefined : value;
                                        setGameSettings(__assign(__assign({}, gameSettings), { themeCreationTime: value }));
                                    } })] }), _jsxs("div", { children: [_jsx("label", { htmlFor: 'themeSelectionTime', children: "Theme Selection Time:" }), _jsx("input", { type: 'number', min: '0', id: 'themeSelectionTime', value: gameSettings.themeSelectionTime, onChange: function (e) {
                                        var _a;
                                        var value = (_a = parseInt(e.target.value)) !== null && _a !== void 0 ? _a : 0;
                                        value = value === 0 ? undefined : value;
                                        setGameSettings(__assign(__assign({}, gameSettings), { themeSelectionTime: value }));
                                    } })] }), _jsxs("div", { children: [_jsx("label", { htmlFor: 'generationCount', children: "Generation Count:" }), _jsx("input", { type: 'number', min: '0', id: 'generationCount', value: gameSettings.generationCount, onChange: function (e) {
                                        var _a;
                                        var value = (_a = parseInt(e.target.value)) !== null && _a !== void 0 ? _a : 0;
                                        value = value <= 0 ? undefined : value;
                                        setGameSettings(__assign(__assign({}, gameSettings), { generationCount: value }));
                                    } })] })] }))] }));
}
export default Lobby;
