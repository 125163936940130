import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-assignment */ // TODO HACK I think this is needed because the linter doesnt recognize types in the backend folder
import '../../App.css';
import React, { useState, useContext, useEffect } from 'react';
import { SenderReceiverContext } from '../../Network/SenderReceiverContext';
import { DreamGame } from './DreamGame';
import { useLocation } from 'react-router-dom';
export function Dreams() {
    var senderReceiver = useContext(SenderReceiverContext);
    var _a = useState(function () { return _jsx("div", { children: "bleep bloop BLEEP bloop bloop BLeep blooP BLOOOP" }); }), phaseComponent = _a[0], setPhaseComponent = _a[1];
    var location = useLocation();
    var gameSettings = location.state;
    useEffect(function () {
        var dreamGame = new DreamGame(senderReceiver, setPhaseComponent, gameSettings);
        void dreamGame.run();
    }, [senderReceiver]);
    return (_jsx("div", { children: phaseComponent }));
}
export default Dreams;
