import { jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
export function TimerView(_a) {
    var timer = _a.timer, _b = _a.defaultColor, defaultColor = _b === void 0 ? 'white' : _b;
    var _c = React.useState(timer.timeRemainingSeconds), timeLeft = _c[0], setTimeLeft = _c[1];
    var _d = React.useState(defaultColor), timeColor = _d[0], setTimeColor = _d[1];
    var handlePause = function () {
        setTimeColor('#add8e6');
        setTimeLeft(timer.timeRemainingSeconds);
    };
    var handleUnpause = function () {
        setTimeColor(defaultColor);
        setTimeLeft(timer.timeRemainingSeconds);
    };
    var handleEnd = function () {
        setTimeColor('red');
        setTimeLeft(0);
    };
    useEffect(function () {
        timer.on('tick', setTimeLeft);
        timer.on('pause', handlePause);
        timer.on('unpause', handleUnpause);
        timer.on('end', handleEnd);
        return function () {
            timer.off('tick', setTimeLeft);
            timer.off('pause', handlePause);
            timer.off('unpause', handleUnpause);
            timer.off('end', handleEnd);
        };
    }, [timer]);
    return _jsxs("h2", { style: { color: timeColor }, children: ["Time Left: ", timeLeft] });
}
