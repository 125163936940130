var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function syncedShuffle(array, sharedRandomNumberGenerator) {
    var _a;
    var copy = __spreadArray([], array, true);
    for (var i = copy.length - 1; i > 0; i--) {
        var j = Math.floor(sharedRandomNumberGenerator.generate() * (i + 1));
        _a = [copy[j], copy[i]], copy[i] = _a[0], copy[j] = _a[1];
    }
    return copy;
}
