var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TypedEmitter } from 'tiny-typed-emitter';
var Timer = /** @class */ (function (_super) {
    __extends(Timer, _super);
    function Timer(durationSeconds) {
        var _this = _super.call(this) || this;
        _this.startTime = -1;
        _this.pausedTimeLeft = -1;
        _this._ended = false;
        _this.duration = durationSeconds * 1000;
        return _this;
    }
    Object.defineProperty(Timer.prototype, "unstarted", {
        get: function () {
            return this.startTime === -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Timer.prototype, "paused", {
        get: function () {
            return this.pausedTimeLeft !== -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Timer.prototype, "ended", {
        get: function () {
            return this._ended;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Timer.prototype, "timeRemainingMs", {
        get: function () {
            return Math.max(0, (this.duration - (performance.now() - this.startTime)));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Timer.prototype, "timeRemainingSeconds", {
        get: function () {
            return Math.floor(Math.max(0, (this.duration - (performance.now() - this.startTime)) / 1000));
        },
        enumerable: false,
        configurable: true
    });
    Timer.prototype.start = function () {
        if (this.paused) {
            this.unpause();
        }
        else if (this.unstarted) {
            this.startFresh();
        }
    };
    Timer.prototype.pause = function () {
        if (this.paused || this.unstarted) {
            return;
        }
        this.pausedTimeLeft = this.timeRemainingMs;
        this.emit('pause', this.timeRemainingSeconds);
        clearInterval(this.ticker);
    };
    Timer.prototype.addDuration = function (seconds) {
        if (this.ended) {
            return;
        }
        var additionalMs = seconds * 1000;
        this.duration += additionalMs;
        if (this.paused) {
            this.pausedTimeLeft += additionalMs;
        }
    };
    Timer.prototype.decoratePromise = function (turn, resolve, reject) {
        var _this = this;
        var rejecter = function (reason) {
            turn.setLostTurn();
            reject(reason);
        };
        var resolver = function (value) {
            if (turn.lostStatus) {
                return;
            }
            _this.off('end', rejecter);
            resolve(value);
        };
        if (this.ended) {
            rejecter();
        }
        else {
            this.on('end', rejecter);
        }
        return { resolver: resolver, rejecter: rejecter };
    };
    Timer.prototype.startFresh = function () {
        this.startTime = performance.now();
        this.startTicking();
    };
    Timer.prototype.unpause = function () {
        if (!this.paused) {
            return;
        }
        this.startTime = performance.now() - (this.duration - this.pausedTimeLeft);
        this.pausedTimeLeft = -1;
        this.startTicking();
        this.emit('unpause', this.timeRemainingSeconds);
    };
    Timer.prototype.startTicking = function () {
        var _this = this;
        if (this.ticker) {
            clearInterval(this.ticker);
        }
        this.ticker = setInterval(function () {
            var timeRemaining = _this.timeRemainingSeconds;
            _this.emit('tick', timeRemaining);
            if (timeRemaining <= 0) {
                clearInterval(_this.ticker);
                _this._ended = true;
                _this.emit('end');
            }
        }, 1000);
    };
    return Timer;
}(TypedEmitter));
export default Timer;
