export var PretenderPlayerType;
(function (PretenderPlayerType) {
    PretenderPlayerType[PretenderPlayerType["Revolutionary"] = 0] = "Revolutionary";
    PretenderPlayerType[PretenderPlayerType["ExRevolutionary"] = 1] = "ExRevolutionary";
    PretenderPlayerType[PretenderPlayerType["BotLover"] = 2] = "BotLover";
    PretenderPlayerType[PretenderPlayerType["Bot"] = 3] = "Bot";
})(PretenderPlayerType || (PretenderPlayerType = {}));
var PretenderPlayer = /** @class */ (function () {
    function PretenderPlayer(playerId, playerType) {
        this.playerId = playerId;
        this.playerType = playerType;
    }
    return PretenderPlayer;
}());
export { PretenderPlayer };
