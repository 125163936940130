var Turn = /** @class */ (function () {
    function Turn() {
        this.lostTurn = false;
    }
    Object.defineProperty(Turn.prototype, "lostStatus", {
        get: function () {
            return this.lostTurn;
        },
        enumerable: false,
        configurable: true
    });
    Turn.prototype.setLostTurn = function () {
        this.lostTurn = true;
    };
    return Turn;
}());
export default Turn;
