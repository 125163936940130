var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var DreamScore = /** @class */ (function () {
    function DreamScore(playerIds) {
        this.scores = [];
        this.scores = playerIds.map(function (playerId) { return ({ playerId: playerId, score: 0 }); });
    }
    DreamScore.prototype.getScores = function () {
        return __spreadArray([], this.scores, true);
    };
    DreamScore.prototype.updateScores = function (promptVotes) {
        var _this = this;
        promptVotes.forEach(function (vote) {
            var scoreToAdd = _this.getScoreToAdd(vote);
            _this.addScore(vote.voteForId, scoreToAdd);
        });
        this.scores = this.scores.sort(function (a, b) { return b.score - a.score; });
    };
    DreamScore.prototype.getScoreToAdd = function (vote) {
        if (vote.voterId === vote.voteForId) {
            return 0;
        }
        return 1;
    };
    DreamScore.prototype.addScore = function (playerId, score) {
        var scoreRecord = this.scores.find(function (s) { return s.playerId === playerId; });
        if (scoreRecord) {
            scoreRecord.score += score;
        }
        else {
            this.scores.push({ playerId: playerId, score: score });
        }
    };
    return DreamScore;
}());
export { DreamScore };
