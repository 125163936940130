var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { fetchMinigames } from '../Assets/Data/minigamesService';
import '../App.css';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Game from '../Games/Game';
import { SenderReceiverContext } from '../Network/SenderReceiverContext';
import { GameType } from '../../../Backend/shared_types/Types';
var canvasStyles = {
    border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.25rem',
};
function Home() {
    var _this = this;
    console.log('IM HOME');
    var _a = useState([]), minigames = _a[0], setMinigames = _a[1];
    var _b = useState(''), existingGameCode = _b[0], setExistingGameCode = _b[1];
    var senderReceiver = useContext(SenderReceiverContext);
    var navigate = useNavigate();
    useEffect(function () {
        function loadMinigames() {
            return __awaiter(this, void 0, void 0, function () {
                var minigameData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetchMinigames()];
                        case 1:
                            minigameData = _a.sent();
                            setMinigames(minigameData);
                            return [2 /*return*/];
                    }
                });
            });
        }
        void loadMinigames();
    }, []);
    function handleCreateGameClick(minigame) {
        return __awaiter(this, void 0, void 0, function () {
            var game;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Game.createGame(senderReceiver, minigame.gameType)];
                    case 1:
                        game = _a.sent();
                        senderReceiver.activeGame = game;
                        console.log('Game created: ', game);
                        navigate("/Lobby/".concat(GameType[minigame.gameType], "/").concat(game.gameCode));
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleChange(e) {
        setExistingGameCode(e.target.value);
    }
    function handleJoinGameClick(e) {
        return __awaiter(this, void 0, void 0, function () {
            var cleanedCode, _a, host, players, gameType, game;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        e.preventDefault();
                        cleanedCode = existingGameCode.trim().toUpperCase();
                        return [4 /*yield*/, senderReceiver.emitWithAck('JoinSession', cleanedCode)];
                    case 1:
                        _a = _b.sent(), host = _a.host, players = _a.players, gameType = _a.gameType;
                        game = new Game(host, players, gameType, cleanedCode);
                        senderReceiver.activeGame = game;
                        console.log('I joined successfully');
                        navigate("/Lobby/".concat(GameType[gameType], "/").concat(cleanedCode));
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs("div", { className: 'App', children: [_jsx("h1", { children: "Multiplayer Minigames" }), _jsx("h2", { children: "Join a game: " }), _jsxs("form", { onSubmit: handleJoinGameClick, children: [_jsx("input", { type: 'text', value: existingGameCode, onChange: handleChange }), _jsx("button", { type: 'submit', children: "Join" })] }), _jsx("h2", { children: "Or create a game: " }), _jsx("div", { className: 'minigame-grid', children: minigames.map(function (minigame) { return (_jsxs("div", { onClick: minigame.beta ? undefined : function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                        return [2 /*return*/, handleCreateGameClick(minigame)];
                    }); }); }, className: 'minigame-widget', children: [_jsxs("div", { className: 'minigame-image-container', children: [_jsx("img", { src: minigame.imgSrc, alt: minigame.name, className: "minigame-image".concat(minigame.beta ? ' beta' : '') }), minigame.beta && _jsx("div", { className: 'construction-overlay' })] }), _jsx("h2", { className: 'minigame-title', children: minigame.name })] }, minigame.gameType)); }) })] }));
}
export default Home;
