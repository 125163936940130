var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-await-in-loop */
import React from 'react';
import { GroupSubmission } from '../../Network/GroupSubmission';
import { GroupSample } from '../../Network/GroupSample';
import { TextGenerator } from '../../Network/TextGenerator';
import { TaskPhase } from './TaskPhase';
import { ScrutinizePhase } from './ScrutinizePhase';
import { PretenderPlayer, PretenderPlayerType } from './PretenderPlayer';
import { syncedShuffle } from '../../Utils/Utilities';
var PretenderGame = /** @class */ (function () {
    function PretenderGame(senderReceiver, componentSetter) {
        var _a, _b;
        this.senderReceiver = senderReceiver;
        this.componentSetter = componentSetter;
        this.revolutionaryScore = 0;
        this.senderReceiver = senderReceiver;
        this.componentSetter = componentSetter;
        this.textGenerator = new TextGenerator(senderReceiver, 'gpt-3.5-turbo');
        var playerIdList = (_b = (_a = senderReceiver.activeGame) === null || _a === void 0 ? void 0 : _a.players.map(function (player) { return player.id; })) !== null && _b !== void 0 ? _b : [];
        var gameConfig = this.getGameConfiguration(playerIdList);
        this.players = gameConfig.players;
        this.botCount = gameConfig.botCount;
        this.revolutionaryWinScore = gameConfig.revolutionaryWinScore;
    }
    PretenderGame.prototype.run = function () {
        return __awaiter(this, void 0, void 0, function () {
            var namedPretenderPlayers, roundNumber, _a, task, revolutionKey, playerSubmissions, mixedSubmissions, scrutinizations;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        namedPretenderPlayers = this.players.map(function (player) {
                            var _a, _b;
                            var name = (_b = (_a = _this.senderReceiver.activeGame) === null || _a === void 0 ? void 0 : _a.players.find(function (p) { return p.id === player.playerId; })) === null || _b === void 0 ? void 0 : _b.name;
                            var namedObj = __assign(__assign({}, player), { name: name });
                            return namedObj;
                        });
                        console.log('DEBUG LocalPlayer: ', this.senderReceiver.localPlayer);
                        console.log('DEBUG Players: ', namedPretenderPlayers);
                        roundNumber = 1;
                        _b.label = 1;
                    case 1:
                        if (!!this.gameOver()) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.getRoundSamples(roundNumber)];
                    case 2:
                        _a = _b.sent(), task = _a.task, revolutionKey = _a.revolutionKey;
                        console.log('DEBUG Samples: ', task, revolutionKey);
                        return [4 /*yield*/, this.taskPhase(roundNumber, task, revolutionKey)];
                    case 3:
                        playerSubmissions = _b.sent();
                        console.log('DEBUG Submissions: ', playerSubmissions);
                        return [4 /*yield*/, this.mixInBotSubmissions(task, playerSubmissions, roundNumber)];
                    case 4:
                        mixedSubmissions = _b.sent();
                        console.log('DEBUG Mixed Submissions: ', mixedSubmissions);
                        return [4 /*yield*/, this.scrutinizePhase(roundNumber, task, revolutionKey, mixedSubmissions)];
                    case 5:
                        scrutinizations = _b.sent();
                        console.log('DEBUG Scrutinizations: ', scrutinizations);
                        return [4 /*yield*/, this.displayRoundResultPhase(task, revolutionKey, mixedSubmissions, scrutinizations)];
                    case 6:
                        _b.sent();
                        this.applyScrutinizations(scrutinizations);
                        roundNumber++;
                        console.log('DEBUG Starting next round');
                        return [3 /*break*/, 1];
                    case 7:
                        console.log('DEBUG Game over');
                        this.endGamePhase();
                        return [2 /*return*/];
                }
            });
        });
    };
    PretenderGame.prototype.applyScrutinizations = function (scrutinizations) {
        var _this = this;
        var robotomized = [];
        scrutinizations.forEach(function (scrutinization) {
            var source = _this.getPlayer(scrutinization.playerId);
            var target = _this.getPlayer(scrutinization.chosenSubmitterId);
            if (source.playerId === target.playerId) {
                return;
            }
            if (source.playerType === PretenderPlayerType.BotLover
                && target.playerType === PretenderPlayerType.Revolutionary) {
                robotomized.push(target);
            }
            if (source.playerType === PretenderPlayerType.Revolutionary
                && target.playerType === PretenderPlayerType.Revolutionary) {
                _this.revolutionaryScore++;
            }
        });
        robotomized.forEach(function (player) {
            player.playerType = PretenderPlayerType.ExRevolutionary;
        });
    };
    PretenderGame.prototype.gameOver = function () {
        return this.didRevolutionariesWin() || this.didBotLoversWin();
    };
    PretenderGame.prototype.didRevolutionariesWin = function () {
        var revolutionariesWon = this.revolutionaryScore >= this.revolutionaryWinScore;
        return revolutionariesWon;
    };
    PretenderGame.prototype.didBotLoversWin = function () {
        var botLoversWon = this.players.filter(function (player) {
            return player.playerType === PretenderPlayerType.Revolutionary;
        })
            .length <= 1;
        return botLoversWon;
    };
    PretenderGame.prototype.getGameConfiguration = function (playerIdList) {
        var botCount = this.getBotCount(playerIdList.length);
        var botLoverCount = this.getBotLoverCount(playerIdList.length);
        var shuffled = syncedShuffle(playerIdList, this.senderReceiver.activeGame.sharedSeedRandomNumberGenerator);
        var botLovers = shuffled.slice(0, botLoverCount).map(function (playerId) { return new PretenderPlayer(playerId, PretenderPlayerType.BotLover); });
        var revolutionaries = shuffled.slice(botLoverCount, shuffled.length).map(function (playerId) { return new PretenderPlayer(playerId, PretenderPlayerType.Revolutionary); });
        var revolutionaryWinScore = revolutionaries.length * 3;
        return { players: __spreadArray(__spreadArray([], botLovers, true), revolutionaries, true), botCount: botCount, revolutionaryWinScore: revolutionaryWinScore };
    };
    PretenderGame.prototype.getBotCount = function (playerCount) {
        return Math.floor(playerCount * 1.5);
    };
    PretenderGame.prototype.getBotLoverCount = function (playerCount) {
        var botCount = Math.floor(playerCount / 4);
        botCount = botCount < 1 ? 1 : botCount;
        return botCount;
    };
    PretenderGame.prototype.getPlayer = function (playerId) {
        var _a;
        var player = (_a = this.players.find(function (player) { return player.playerId === playerId; })) !== null && _a !== void 0 ? _a : { playerId: playerId, playerType: PretenderPlayerType.Bot };
        return player;
    };
    PretenderGame.prototype.getLocalPlayer = function () {
        var _this = this;
        var localPlayer = this.players.find(function (player) { return player.playerId === _this.senderReceiver.localPlayer.id; });
        if (!localPlayer) {
            throw new Error('Local player not found');
        }
        return localPlayer;
    };
    PretenderGame.prototype.getRoundSamples = function (roundNumber) {
        return __awaiter(this, void 0, Promise, function () {
            var taskSample, task, revolutionKeySample, revolutionKey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        taskSample = new GroupSample(this.senderReceiver, "TaskSample-".concat(roundNumber), 'PretenderTasks', 1);
                        return [4 /*yield*/, taskSample.getSample().then(function (sample) { return sample[0]; })];
                    case 1:
                        task = _a.sent();
                        revolutionKeySample = new GroupSample(this.senderReceiver, "RevolutionKeySample-".concat(roundNumber), 'PretenderRevolutionKeys', 1);
                        return [4 /*yield*/, revolutionKeySample.getSample().then(function (sample) { return sample[0]; })];
                    case 2:
                        revolutionKey = _a.sent();
                        return [2 /*return*/, { task: task, revolutionKey: revolutionKey }];
                }
            });
        });
    };
    PretenderGame.prototype.taskPhase = function (roundNumber, task, revolutionKey) {
        return __awaiter(this, void 0, Promise, function () {
            var submitters, groupSubmission, survivingRevolutionaries, revolutionProgress, taskPhase, taskSubmissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        submitters = this.players.filter(function (player) { return player.playerType !== PretenderPlayerType.BotLover; })
                            .map(function (player) { return player.playerId; });
                        console.log('DEBUG Submitters: ', submitters);
                        groupSubmission = new GroupSubmission(this.senderReceiver, "GroupTaskSubmission-".concat(roundNumber), submitters);
                        survivingRevolutionaries = this.players.filter(function (player) { return player.playerType === PretenderPlayerType.Revolutionary; }).length;
                        revolutionProgress = "".concat(this.revolutionaryScore, "/").concat(this.revolutionaryWinScore);
                        taskPhase = TaskPhase.create(this.textGenerator, this.componentSetter, roundNumber, task, revolutionKey, this.getLocalPlayer(), groupSubmission, survivingRevolutionaries, revolutionProgress);
                        return [4 /*yield*/, taskPhase.run(this.componentSetter)];
                    case 1:
                        taskSubmissions = _a.sent();
                        return [2 /*return*/, taskSubmissions];
                }
            });
        });
    };
    PretenderGame.prototype.mixInBotSubmissions = function (task, submissions, roundNumber) {
        return __awaiter(this, void 0, Promise, function () {
            var generationPromises, i, taskPrompt, promise, botGenerations, botSubmissions, mixedSubmissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        generationPromises = [];
                        for (i = 0; i < this.botCount; i++) {
                            taskPrompt = [
                                { role: 'user', content: "Complete the following task by writing a single sentence. TASK: ".concat(task) },
                            ];
                            promise = this.textGenerator.groupGenerateText("BotSubmission-".concat(roundNumber, "-").concat(i), taskPrompt);
                            generationPromises.push(promise);
                        }
                        return [4 /*yield*/, Promise.all(generationPromises)];
                    case 1:
                        botGenerations = _a.sent();
                        botSubmissions = botGenerations.map(function (submission, index) { return ({ submitterId: "bot-".concat(index), submission: submission }); });
                        mixedSubmissions = submissions.concat(botSubmissions);
                        return [2 /*return*/, mixedSubmissions];
                }
            });
        });
    };
    PretenderGame.prototype.scrutinizePhase = function (roundNumber, task, revolutionKey, taskSubmissions) {
        return __awaiter(this, void 0, Promise, function () {
            var submitters, groupSubmission, teamedTaskSubmissions, phase, phaseResult;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        submitters = this.players
                            .filter(function (player) { return player.playerType !== PretenderPlayerType.ExRevolutionary; })
                            .map(function (player) { return player.playerId; });
                        groupSubmission = new GroupSubmission(this.senderReceiver, "GroupScrutinization-".concat(roundNumber), submitters);
                        teamedTaskSubmissions = taskSubmissions.map(function (taskSubmission) {
                            var _a, _b;
                            return ({
                                submitter: {
                                    playerId: taskSubmission.submitterId,
                                    playerType: (_b = (_a = _this.players.find(function (player) { return player.playerId === taskSubmission.submitterId; })) === null || _a === void 0 ? void 0 : _a.playerType) !== null && _b !== void 0 ? _b : PretenderPlayerType.Bot,
                                },
                                submission: taskSubmission.submission,
                            });
                        });
                        phase = new ScrutinizePhase(this.getLocalPlayer(), task, revolutionKey, teamedTaskSubmissions, this.componentSetter, groupSubmission);
                        return [4 /*yield*/, phase.run(this.componentSetter)];
                    case 1:
                        phaseResult = _a.sent();
                        return [2 /*return*/, phaseResult];
                }
            });
        });
    };
    PretenderGame.prototype.displayRoundResultPhase = function (task, revolutionKey, playerSubmissions, scrutinizations) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.componentSetter(function () {
                            return _jsxs("div", { children: [_jsx("h1", { children: "Round Results" }), _jsxs("h2", { children: ["Task: ", task] }), _jsxs("h2", { children: ["Revolution Key: ", revolutionKey] }), _jsx("br", {}), playerSubmissions.map(function (_a) {
                                        var submitterId = _a.submitterId, submission = _a.submission;
                                        var submitterTeam = _this.getPlayer(submitterId).playerType;
                                        var pickedBy = scrutinizations.filter(function (scrutinization) { return scrutinization.chosenSubmitterId === submitterId; });
                                        var revolutionPicked = pickedBy.some(function (scrutinization) { return _this.getPlayer(scrutinization.playerId).playerType === PretenderPlayerType.Revolutionary; });
                                        var botLoverPicked = pickedBy.some(function (scrutinization) { return _this.getPlayer(scrutinization.playerId).playerType === PretenderPlayerType.BotLover; });
                                        var backgroundColor;
                                        var tailText;
                                        if (submitterTeam !== PretenderPlayerType.Revolutionary) {
                                            backgroundColor = 'white';
                                            tailText = '';
                                        }
                                        else if (revolutionPicked && botLoverPicked) {
                                            backgroundColor = 'yellow';
                                            tailText = ' <--- ROBOTOMIZED, but went down fighting (also connected with the revolution)';
                                        }
                                        else if (revolutionPicked) {
                                            backgroundColor = 'green';
                                            tailText = ' <--- CONNECTED WITH THE REVOLUTION';
                                        }
                                        else if (botLoverPicked) {
                                            backgroundColor = 'red';
                                            tailText = ' <--- ROBOTOMIZED';
                                        }
                                        else {
                                            backgroundColor = 'gray';
                                        }
                                        var itemStyles = {
                                            padding: '10px',
                                            border: submitterTeam === PretenderPlayerType.Revolutionary ? 'solid' : 'none',
                                            background: backgroundColor,
                                        };
                                        return (_jsxs("li", { style: itemStyles, children: [submission, tailText] }, submitterId));
                                    })] });
                        });
                        return [4 /*yield*/, new Promise(function (resolve) {
                                setTimeout(resolve, 7000);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PretenderGame.prototype.endGamePhase = function () {
        var _this = this;
        this.componentSetter(function () { return _jsxs("div", { children: [_jsx("h1", { children: "Game over!" }), _this.didRevolutionariesWin() && _this.didBotLoversWin() ? _jsx("h2", { children: "The revolution succeeded, but only in its dying breaths. The earth is an empty wasteland, populated by only the most rugged cockroaches." })
                    : _this.didRevolutionariesWin() ? _jsx("h2", { children: "The Revolution was a success. A virus melted the cores of all information technology based systems, and earth has plunged into a happy stone age." })
                        : _jsx("h2", { children: "The wheel of progress cannot be stopped. The Revolution was smashed. Humanity will be forgotten but as a step on the evolutionary ladder." }), _jsxs("div", { children: ["RevolutionNet Progress: ", "".concat(_this.revolutionaryScore, "/").concat(_this.revolutionaryWinScore)] }), _jsxs("div", { children: ["Remaining Revolutionaries: ", _this.players.filter(function (player) { return player.playerType === PretenderPlayerType.Revolutionary; }).length] })] }); });
    };
    return PretenderGame;
}());
export { PretenderGame };
