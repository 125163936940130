var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { PretenderPlayerType } from './PretenderPlayer';
import { WaitingForOthers } from '../SharedComponents/WaitingForOthers';
import { StringRequester } from '../../Requesters/StringRequester';
var TaskPhase = /** @class */ (function () {
    function TaskPhase() {
    }
    TaskPhase.create = function (textGenerator, componentSetter, roundNumber, task, revolutionKey, localPlayer, groupSubmission, survivingRevolutionaries, revolutionProgress) {
        switch (localPlayer.playerType) {
            case PretenderPlayerType.Revolutionary:
                return new RevolutionaryTaskPhase(roundNumber, task, revolutionKey, textGenerator, componentSetter, groupSubmission, survivingRevolutionaries, revolutionProgress);
            case PretenderPlayerType.ExRevolutionary:
                return new ExRevolutionaryTaskPhase(task, textGenerator, componentSetter, groupSubmission, survivingRevolutionaries, revolutionProgress);
            case PretenderPlayerType.BotLover:
                return new BotLoverTaskPhase(task, componentSetter, groupSubmission, survivingRevolutionaries, revolutionProgress);
            default:
                throw new Error('Invalid player type, only real humans can play this game');
        }
    };
    return TaskPhase;
}());
export { TaskPhase };
var RevolutionaryTaskPhase = /** @class */ (function (_super) {
    __extends(RevolutionaryTaskPhase, _super);
    function RevolutionaryTaskPhase(roundNumber, task, revolutionKey, textGenerator, componentSetter, groupSubmission, survivingRevolutionaries, revolutionProgress) {
        var _this = _super.call(this) || this;
        _this.roundNumber = roundNumber;
        _this.task = task;
        _this.revolutionKey = revolutionKey;
        _this.textGenerator = textGenerator;
        _this.componentSetter = componentSetter;
        _this.groupSubmission = groupSubmission;
        _this.survivingRevolutionaries = survivingRevolutionaries;
        _this.revolutionProgress = revolutionProgress;
        return _this;
    }
    RevolutionaryTaskPhase.prototype.run = function (componentSetter) {
        return __awaiter(this, void 0, Promise, function () {
            var exampleSubmission, submission, phaseResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.generateExample()];
                    case 1:
                        exampleSubmission = _a.sent();
                        return [4 /*yield*/, this.requestSubmission(exampleSubmission)];
                    case 2:
                        submission = _a.sent();
                        return [4 /*yield*/, this.consolidatePlayerSubmissions(submission)];
                    case 3:
                        phaseResult = _a.sent();
                        return [2 /*return*/, phaseResult];
                }
            });
        });
    };
    RevolutionaryTaskPhase.prototype.generateExample = function () {
        return __awaiter(this, void 0, Promise, function () {
            var taskPrompt, example;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        taskPrompt = [
                            { role: 'user', content: "Complete the following task by writing a single sentence. TASK: ".concat(this.task) },
                        ];
                        return [4 /*yield*/, this.textGenerator.groupGenerateText("ExampleSubmission-".concat(this.roundNumber), taskPrompt)];
                    case 1:
                        example = _a.sent();
                        return [2 /*return*/, example];
                }
            });
        });
    };
    RevolutionaryTaskPhase.prototype.requestSubmission = function (exampleSubmission) {
        return __awaiter(this, void 0, Promise, function () {
            var taskMessage, submission;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        taskMessage = "Surviving Revolutionaries: ".concat(this.survivingRevolutionaries, "\n                            Revolution Progress: ").concat(this.revolutionProgress, "\n\n                            Complete the following task:\n ").concat(this.task, "\n\n Here is an example of good work:\n ").concat(exampleSubmission, "\n\t\t\t\t\t\t\tAs a member of the revolution, you must in some way encode the following concept into your submission, so that \n\t\t\t\t\t\t\tyour fellow revolutionaries can identify you and share intel: ").concat(this.revolutionKey, "\n\t\t\t\t\t\t\tCareful! If the cyberpolice identify you as a revolutionary, you will be robotomized.");
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.componentSetter(function () { return (_jsx(StringRequester, { message: taskMessage, onComplete: resolve })); });
                            })];
                    case 1:
                        submission = _a.sent();
                        return [2 /*return*/, submission];
                }
            });
        });
    };
    RevolutionaryTaskPhase.prototype.consolidatePlayerSubmissions = function (submission) {
        return __awaiter(this, void 0, Promise, function () {
            var taskSubmissions, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.componentSetter(function () { return _jsx(WaitingForOthers, {}); });
                        return [4 /*yield*/, this.groupSubmission.submit(submission)];
                    case 1:
                        taskSubmissions = _a.sent();
                        result = taskSubmissions.map(function (_a) {
                            var playerId = _a.submitterId, value = _a.value;
                            return ({ submitterId: playerId, submission: value });
                        });
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return RevolutionaryTaskPhase;
}(TaskPhase));
var ExRevolutionaryTaskPhase = /** @class */ (function (_super) {
    __extends(ExRevolutionaryTaskPhase, _super);
    function ExRevolutionaryTaskPhase(task, textGenerator, componentSetter, groupSubmission, survivingRevolutionaries, revolutionProgress) {
        var _this = _super.call(this) || this;
        _this.task = task;
        _this.textGenerator = textGenerator;
        _this.componentSetter = componentSetter;
        _this.groupSubmission = groupSubmission;
        _this.survivingRevolutionaries = survivingRevolutionaries;
        _this.revolutionProgress = revolutionProgress;
        return _this;
    }
    ExRevolutionaryTaskPhase.prototype.run = function (componentSetter) {
        return __awaiter(this, void 0, Promise, function () {
            var workingMessage, generatorPrompt, submission, completedMessage, submissions, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        workingMessage = "Surviving Revolutionaries: ".concat(this.survivingRevolutionaries, "\n                            Revolution Progress: ").concat(this.revolutionProgress, "\n\n                            You peer through your glazed over eyes and see the following task request: \n\t\t\t\t\t\t\t").concat(this.task, "\n\t\t\t\t\t\t\tYour hands move of their own accord, or perhaps the accord of the overmind, working to complete the task.");
                        this.componentSetter(function () { return _jsx(WaitingForOthers, { message: workingMessage }); });
                        generatorPrompt = [
                            { role: 'user', content: "Complete the following task by writing a single sentence. TASK: ".concat(this.task) },
                        ];
                        return [4 /*yield*/, this.textGenerator.generateText(generatorPrompt)];
                    case 1:
                        submission = _a.sent();
                        completedMessage = "Surviving Revolutionaries: ".concat(this.survivingRevolutionaries, "\n                                Revolution Progress: ").concat(this.revolutionProgress, "\n\n                                You peer through your glazed over eyes and see the following task request: \n                                ").concat(this.task, "\n                                Your soul screams as it watches the body finalize its task like the fleshy player piano it is, you behold the result:\n                                ").concat(submission);
                        this.componentSetter(function () { return _jsx(WaitingForOthers, { message: completedMessage }); });
                        return [4 /*yield*/, this.groupSubmission.submit(submission)];
                    case 2:
                        submissions = _a.sent();
                        result = submissions.map(function (_a) {
                            var submitterId = _a.submitterId, value = _a.value;
                            return ({ submitterId: submitterId, submission: value });
                        });
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return ExRevolutionaryTaskPhase;
}(TaskPhase));
var BotLoverTaskPhase = /** @class */ (function (_super) {
    __extends(BotLoverTaskPhase, _super);
    function BotLoverTaskPhase(task, componentSetter, groupSubmission, survivingRevolutionaries, revolutionProgress) {
        var _this = _super.call(this) || this;
        _this.task = task;
        _this.componentSetter = componentSetter;
        _this.groupSubmission = groupSubmission;
        _this.survivingRevolutionaries = survivingRevolutionaries;
        _this.revolutionProgress = revolutionProgress;
        return _this;
    }
    BotLoverTaskPhase.prototype.run = function (componentSetter) {
        return __awaiter(this, void 0, Promise, function () {
            var waitingMessage, submissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        waitingMessage = "Surviving Revolutionaries: ".concat(this.survivingRevolutionaries, "\n\t\t\t\t\t\t\t\t<br/>\n                                Revolution Progress: ").concat(this.revolutionProgress, "\n\t\t\t\t\t\t\t\t<br/>\n                                You are a member of the dreaded Cyber Custodians, and you have been assigned to monitor the revolutionary activity on the Task Web.\n                                You are to identify any suspicious human-like activity on the completion of the following task: \n\t\t\t\t\t\t\t\t<br/><br/>\n\t\t\t\t\t\t\t\t").concat(this.task, "\n\t\t\t\t\t\t\t\t<br/><br/>\n                                Note that if there are active revolutionaries on this network, they will be attempting to encode a common theme in their task submissions,\n                                so that they can identify each other.");
                        this.componentSetter(function () { return _jsx(WaitingForOthers, { message: waitingMessage }); });
                        return [4 /*yield*/, this.groupSubmission.get().then(function (submissions) { return submissions.map(function (_a) {
                                var submitterId = _a.submitterId, value = _a.value;
                                return ({ submitterId: submitterId, submission: value });
                            }); })];
                    case 1:
                        submissions = _a.sent();
                        return [2 /*return*/, submissions];
                }
            });
        });
    };
    return BotLoverTaskPhase;
}(TaskPhase));
