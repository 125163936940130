var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { StringRequester } from '../../Requesters/StringRequester';
import { WaitingForOthers } from '../SharedComponents/WaitingForOthers';
import Timer from '../../Utils/Timer';
import Turn from './Turn';
import { TimerView } from '../../Utils/Components/TimerView';
var StringSubmissionPhase = /** @class */ (function () {
    function StringSubmissionPhase(componentSetter, stringSubmission, message, localPlayerName, phaseTime, fixedSubmission) {
        this.componentSetter = componentSetter;
        this.stringSubmission = stringSubmission;
        this.message = message;
        this.localPlayerName = localPlayerName;
        this.phaseTime = phaseTime;
        this.fixedSubmission = fixedSubmission;
        this.turn = new Turn();
        if (phaseTime) {
            this.phaseTimer = new Timer(phaseTime);
        }
    }
    StringSubmissionPhase.prototype.run = function () {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var localString, _b, phaseResult;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        (_a = this.phaseTimer) === null || _a === void 0 ? void 0 : _a.start();
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.requestString()];
                    case 2:
                        localString = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _b = _c.sent();
                        localString = "".concat(this.localPlayerName, " will not waste Shlippy's time.");
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, this.consolidatePlayerSubmissions(localString)];
                    case 5:
                        phaseResult = _c.sent();
                        return [2 /*return*/, phaseResult];
                }
            });
        });
    };
    StringSubmissionPhase.prototype.requestString = function () {
        return __awaiter(this, void 0, void 0, function () {
            var localString;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localString = this.fixedSubmission;
                        if (!!localString) return [3 /*break*/, 2];
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                var _a, _b;
                                var resolver = ((_b = (_a = _this.phaseTimer) === null || _a === void 0 ? void 0 : _a.decoratePromise(_this.turn, resolve, reject)) !== null && _b !== void 0 ? _b : { resolver: resolve }).resolver;
                                _this.componentSetter(function () { return _jsxs("div", { children: [_this.phaseTimer && _jsx(TimerView, { timer: _this.phaseTimer }), _jsx(StringRequester, { message: _this.message, onComplete: resolver })] }); });
                            })];
                    case 1:
                        localString = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, localString];
                }
            });
        });
    };
    StringSubmissionPhase.prototype.consolidatePlayerSubmissions = function (localGuess) {
        return __awaiter(this, void 0, void 0, function () {
            var submissions, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.componentSetter(function () { return _jsx(WaitingForOthers, {}); });
                        return [4 /*yield*/, this.stringSubmission.submit(localGuess)];
                    case 1:
                        submissions = _a.sent();
                        result = submissions.map(function (_a) {
                            var playerId = _a.submitterId, value = _a.value;
                            return ({ playerId: playerId, submission: value });
                        });
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return StringSubmissionPhase;
}());
export { StringSubmissionPhase };
