import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState } from 'react';
import SenderReceiver from './SenderReceiver';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
var BackendUrl = process.env.REACT_APP_BACKEND_URL;
export var SenderReceiverContext = createContext({});
export function SenderReceiverContextProvider(_a) {
    var children = _a.children;
    var senderReceiver = useState(new SenderReceiver(BackendUrl))[0];
    return (_jsx(SenderReceiverContext.Provider, { value: senderReceiver, children: children }));
}
