var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SelectionRequester } from '../../Requesters/SelectionRequester';
import { WaitingForOthers } from '../SharedComponents/WaitingForOthers';
import { TimerView } from '../../Utils/Components/TimerView';
import Turn from './Turn';
var ImageVotingPhase = /** @class */ (function () {
    function ImageVotingPhase(componentSetter, imageVoteSubmission, phaseData, localPlayerId, timer) {
        this.componentSetter = componentSetter;
        this.imageVoteSubmission = imageVoteSubmission;
        this.phaseData = phaseData;
        this.localPlayerId = localPlayerId;
        this.timer = timer;
        this.turn = new Turn();
    }
    ImageVotingPhase.prototype.run = function () {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var vote, _b, phaseResult;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        (_a = this.timer) === null || _a === void 0 ? void 0 : _a.start();
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.requestVote()];
                    case 2:
                        vote = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _b = _c.sent();
                        vote = this.localPlayerId;
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, this.consolidatePlayerSubmissions(vote)];
                    case 5:
                        phaseResult = _c.sent();
                        return [2 /*return*/, phaseResult];
                }
            });
        });
    };
    ImageVotingPhase.prototype.requestVote = function () {
        return __awaiter(this, void 0, void 0, function () {
            var vote, votePlayerId;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            var _a, _b;
                            var resolver = ((_b = (_a = _this.timer) === null || _a === void 0 ? void 0 : _a.decoratePromise(_this.turn, resolve, reject)) !== null && _b !== void 0 ? _b : { resolver: resolve }).resolver;
                            _this.componentSetter(function () { return _jsxs("div", { children: [_this.timer && _jsx(TimerView, { timer: _this.timer }), _jsx("h1", { children: "So, who did the best?" }), _jsx(SelectionRequester, { onComplete: resolver, children: _this.phaseData.imageGenerations.map(function (_a) {
                                            var playerId = _a.playerId, prompt = _a.prompt, imageUrl = _a.imageUrl;
                                            return (_jsx(_Fragment, { children: _jsxs("figure", { children: [_jsx("img", { src: imageUrl, alt: "".concat(playerId, "'s image") }), _jsx("figcaption", { style: { color: 'black' }, children: prompt })] }, playerId) }));
                                        }) }), _jsx("h2", { children: _this.phaseData.theme })] }); });
                        })];
                    case 1:
                        vote = _a.sent();
                        votePlayerId = this.phaseData.imageGenerations[vote].playerId;
                        this.componentSetter(function () { return _jsx(WaitingForOthers, {}); });
                        return [2 /*return*/, votePlayerId];
                }
            });
        });
    };
    ImageVotingPhase.prototype.consolidatePlayerSubmissions = function (vote) {
        return __awaiter(this, void 0, void 0, function () {
            var submissions, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.imageVoteSubmission.submit(vote)];
                    case 1:
                        submissions = _a.sent();
                        result = submissions.map(function (_a) {
                            var playerId = _a.submitterId, value = _a.value;
                            return ({ voterId: playerId, voteForId: value });
                        });
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return ImageVotingPhase;
}());
export { ImageVotingPhase };
