/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-statements-per-line */
/* eslint-disable no-bitwise */
var RandomNumberGenerator = /** @class */ (function () {
    function RandomNumberGenerator(seed) {
        var hashedSeed = this.cyrb128(seed);
        this.generator = this.sfc32(hashedSeed[0], hashedSeed[1], hashedSeed[2], hashedSeed[3]);
    }
    RandomNumberGenerator.prototype.generate = function () {
        return this.generator();
    };
    // Hash function to seed sfc32 from a string
    RandomNumberGenerator.prototype.cyrb128 = function (str) {
        var h1 = 1779033703;
        var h2 = 3144134277;
        var h3 = 1013904242;
        var h4 = 2773480762;
        for (var i = 0, k = void 0; i < str.length; i++) {
            k = str.charCodeAt(i);
            h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
            h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
            h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
            h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
        }
        h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
        h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
        h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
        h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
        h1 ^= (h2 ^ h3 ^ h4), h2 ^= h1, h3 ^= h1, h4 ^= h1;
        return [h1 >>> 0, h2 >>> 0, h3 >>> 0, h4 >>> 0];
    };
    // Returns seeded random number generator (Simple Fast Counter)
    RandomNumberGenerator.prototype.sfc32 = function (a, b, c, d) {
        return function () {
            a >>>= 0;
            b >>>= 0;
            c >>>= 0;
            d >>>= 0;
            var t = (a + b) | 0;
            a = b ^ b >>> 9;
            b = c + (c << 3) | 0;
            c = (c << 21 | c >>> 11);
            d = d + 1 | 0;
            t = t + d | 0;
            c = c + t | 0;
            return (t >>> 0) / 4294967296;
        };
    };
    return RandomNumberGenerator;
}());
export { RandomNumberGenerator };
