var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import { ImageGenerationPhase } from './ImageGenerationPhase';
import { GroupSubmission } from '../../Network/GroupSubmission';
import { ImageGenerator } from '../../Network/ImageGenerator';
import { StringSubmissionPhase } from './StringSubmissionPhase';
import { StringVotingPhase } from './StringVotingPhase';
import { DreamScore } from './DreamScore';
import { ImageVotingPhase } from './ImageVotingPhase';
import { GroupSample } from '../../Network/GroupSample';
import { UpdateScoresScreen } from './UpdateScoresScreen';
import Timer from '../../Utils/Timer';
var DreamGame = /** @class */ (function () {
    function DreamGame(senderReceiver, componentSetter, gameSettings) {
        var _a, _b, _c;
        this.gameSettings = gameSettings;
        this.roundDatas = [];
        this.imageGenerator = new ImageGenerator(senderReceiver, (_a = gameSettings.generationCount) !== null && _a !== void 0 ? _a : 1);
        this.senderReceiver = senderReceiver;
        this.componentSetter = componentSetter;
        var playerIdList = (_c = (_b = senderReceiver.activeGame) === null || _b === void 0 ? void 0 : _b.players.map(function (player) { return player.id; })) !== null && _c !== void 0 ? _c : [];
        this.scores = new DreamScore(playerIdList);
    }
    DreamGame.prototype.run = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userTheme;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.round(1)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.round(2)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.themeSelectionPhase()];
                    case 3:
                        userTheme = _a.sent();
                        return [4 /*yield*/, this.round(3, userTheme)];
                    case 4:
                        _a.sent();
                        this.endGamePhase();
                        return [2 /*return*/];
                }
            });
        });
    };
    DreamGame.prototype.round = function (roundNumber, theme) {
        return __awaiter(this, void 0, void 0, function () {
            var groupSample, themeSample, imageGenerations, voteResults, roundData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!theme) return [3 /*break*/, 2];
                        groupSample = new GroupSample(this.senderReceiver, "GroupSample-".concat(roundNumber), 'ImageGameThemes', 1);
                        return [4 /*yield*/, groupSample.getSample()];
                    case 1:
                        themeSample = _a.sent();
                        theme = themeSample[0];
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.imageGenerationPhase("ImageGenerationPhase-".concat(roundNumber), theme)];
                    case 3:
                        imageGenerations = _a.sent();
                        return [4 /*yield*/, this.imageVotingPhase("ImageVotingPhase-".concat(roundNumber), theme, imageGenerations, this.senderReceiver.localPlayer.id)];
                    case 4:
                        voteResults = _a.sent();
                        return [4 /*yield*/, this.updateScoresPhase(theme, imageGenerations, voteResults)];
                    case 5:
                        _a.sent();
                        roundData = { theme: theme, winnerId: voteResults[0].voteForId, prompt: imageGenerations[0].prompt, winnerImageUrl: imageGenerations[0].imageUrl };
                        this.roundDatas.push(roundData);
                        return [2 /*return*/];
                }
            });
        });
    };
    DreamGame.prototype.themeSelectionPhase = function () {
        return __awaiter(this, void 0, void 0, function () {
            var themeSelectionSubmission, themeSelectionPhase, themeSelectionResult, themeVotingSubmission, themeVotingPhase, themeVotingResult, voteWinner, chosenTheme;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        themeSelectionSubmission = new GroupSubmission(this.senderReceiver, 'ThemeSelectionPhase');
                        themeSelectionPhase = new StringSubmissionPhase(this.componentSetter, themeSelectionSubmission, 'Pick a theme. Any Theme. Don\'t disappoint me with something derivative.', this.senderReceiver.localPlayer.name, this.gameSettings.themeCreationTime);
                        return [4 /*yield*/, themeSelectionPhase.run()];
                    case 1:
                        themeSelectionResult = _a.sent();
                        themeVotingSubmission = new GroupSubmission(this.senderReceiver, 'ThemeVotingPhase');
                        themeVotingPhase = new StringVotingPhase(this.componentSetter, themeVotingSubmission, themeSelectionResult, 'Don\'t vote for your own theme. That\'s morally wrong.', this.senderReceiver.localPlayer.id, this.gameSettings.themeSelectionTime);
                        return [4 /*yield*/, themeVotingPhase.run()];
                    case 2:
                        themeVotingResult = _a.sent();
                        voteWinner = this.determineVoteWinner(themeVotingResult);
                        chosenTheme = themeSelectionResult.find(function (_a) {
                            var playerId = _a.playerId;
                            return playerId === voteWinner;
                        }).submission;
                        return [2 /*return*/, chosenTheme];
                }
            });
        });
    };
    DreamGame.prototype.determineVoteWinner = function (votes) {
        var voteCounts = {};
        votes.forEach(function (vote) {
            if (voteCounts[vote.voteForId]) {
                voteCounts[vote.voteForId]++;
            }
            else {
                voteCounts[vote.voteForId] = 1;
            }
        });
        var maxVotes = 0;
        var candidates = [];
        for (var id in voteCounts) {
            if (voteCounts[id] > maxVotes) {
                maxVotes = voteCounts[id];
                candidates.length = 0;
                candidates.push(id);
            }
            else if (voteCounts[id] === maxVotes) {
                candidates.push(id);
            }
        }
        var rng = this.senderReceiver.activeGame.sharedSeedRandomNumberGenerator;
        var winnerId = candidates[Math.floor(rng.generate() * candidates.length)];
        return winnerId;
    };
    DreamGame.prototype.imageGenerationPhase = function (phaseTag, theme) {
        return __awaiter(this, void 0, void 0, function () {
            var phaseTimer, imageGenerationSubmission, imageGenerationPhase, imageGenerationResults;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.gameSettings.generationTime) {
                            phaseTimer = new Timer(this.gameSettings.generationTime);
                        }
                        imageGenerationSubmission = new GroupSubmission(this.senderReceiver, phaseTag);
                        imageGenerationPhase = new ImageGenerationPhase(this.componentSetter, imageGenerationSubmission, this.imageGenerator, theme, this.gameSettings.textToImageModel, this.senderReceiver.localPlayer.name, 2, phaseTimer);
                        return [4 /*yield*/, imageGenerationPhase.run()];
                    case 1:
                        imageGenerationResults = _a.sent();
                        return [2 /*return*/, imageGenerationResults];
                }
            });
        });
    };
    DreamGame.prototype.imageVotingPhase = function (phaseTag, theme, imageGenerations, localPlayerId) {
        return __awaiter(this, void 0, void 0, function () {
            var phaseTimer, imageVotingSubmission, imageVotingPhase, imageVotingResults;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.gameSettings.votingTime) {
                            phaseTimer = new Timer(this.gameSettings.votingTime);
                        }
                        imageVotingSubmission = new GroupSubmission(this.senderReceiver, phaseTag);
                        imageVotingPhase = new ImageVotingPhase(this.componentSetter, imageVotingSubmission, { theme: theme, imageGenerations: imageGenerations }, localPlayerId, phaseTimer);
                        return [4 /*yield*/, imageVotingPhase.run()];
                    case 1:
                        imageVotingResults = _a.sent();
                        return [2 /*return*/, imageVotingResults];
                }
            });
        });
    };
    DreamGame.prototype.updateScoresPhase = function (theme, imageGenerations, voteResults) {
        return __awaiter(this, void 0, void 0, function () {
            var updatePhasePromise;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatePhasePromise = new Promise(function (resolve) {
                            _this.componentSetter(function () { return _jsx(UpdateScoresScreen, { theme: theme, imageGenerations: imageGenerations, dreamScore: _this.scores, votes: voteResults, nameGetter: function (playerId) { return _this.senderReceiver.activeGame.getName(playerId); }, onComplete: resolve }); });
                        });
                        return [4 /*yield*/, updatePhasePromise];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DreamGame.prototype.endGamePhase = function () {
        var _this = this;
        this.componentSetter(function () { return _jsxs("div", { children: [_jsx("h1", { children: "Game over!" }), _this.scores.getScores().map(function (_a) {
                    var _b, _c, _d;
                    var playerId = _a.playerId, score = _a.score;
                    return (_jsxs("div", { children: [_jsx("h2", { children: (_d = (_c = (_b = _this.senderReceiver.activeGame) === null || _b === void 0 ? void 0 : _b.players.find(function (player) { return player.id === playerId; })) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : 'Unknown player' }), _jsx("p", { children: score }), _jsx("br", {})] }, playerId));
                })] }); });
    };
    return DreamGame;
}());
export { DreamGame };
