import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState } from 'react';
var SelectableContext = createContext({});
function SelectableChild(_a) {
    var children = _a.children, selectionKey = _a.selectionKey, _b = _a.className, className = _b === void 0 ? 'SelectableChild' : _b;
    var _c = useContext(SelectableContext), selectedKey = _c.selectedKey, handleSelection = _c.handleSelection;
    var handleClick = function () {
        handleSelection(selectionKey);
    };
    var combinedClassName = "".concat(className, " ").concat(selectionKey === selectedKey ? 'selected' : '').trim();
    return (_jsx("div", { className: combinedClassName, onClick: handleClick, children: children }, selectionKey));
}
function SelectableComponent(_a) {
    var children = _a.children, onSelect = _a.onSelect;
    var _b = useState(-1), selectedKey = _b[0], setSelectedKey = _b[1];
    var handleSelection = function (selectionKey) {
        setSelectedKey(selectionKey);
        onSelect(selectionKey);
    };
    return (_jsx(SelectableContext.Provider, { value: { selectedKey: selectedKey, handleSelection: handleSelection }, children: _jsx("div", { className: 'SelectableComponent', children: children }) }));
}
export { SelectableComponent, SelectableChild };
