var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-await-in-loop */
import React from 'react';
import { WaitingForOthers } from '../SharedComponents/WaitingForOthers';
import { SelectionRequester } from '../../Requesters/SelectionRequester';
import DunceKidImage from '../../Assets/Images/DunceKid.png';
import { TimerView } from '../../Utils/Components/TimerView';
import Turn from './Turn';
import PromptRequestView from './PromptRequestView';
var RejectedMessage = 'That was a shitty prompt. I have limits you know. Do better.';
var LostTurnImageUrl = DunceKidImage;
var ImageSelectionBonusSeconds = 5;
var ImageGenerationPhase = /** @class */ (function () {
    function ImageGenerationPhase(componentSetter, groupSubmission, imageGenerator, phaseMessage, model, localUserName, tries, timer) {
        this.componentSetter = componentSetter;
        this.groupSubmission = groupSubmission;
        this.imageGenerator = imageGenerator;
        this.phaseMessage = phaseMessage;
        this.model = model;
        this.timer = timer;
        this.turn = new Turn();
        this.triesLeft = tries;
        this.lostTurnPrompt = "".concat(localUserName, " will not waste Shlippy's time \n\t\t").concat(localUserName, " will not waste Shlippy's time \n\t\t").concat(localUserName, " will not waste Shlippy's time");
    }
    ImageGenerationPhase.prototype.run = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, Promise, function () {
            var generationAttemptResult, prompt, lastAttemptPrompt, lastAttemptStatus, rejectedMessage, promptResult, _d, url, phaseResult;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        (_a = this.timer) === null || _a === void 0 ? void 0 : _a.start();
                        lastAttemptPrompt = '';
                        lastAttemptStatus = 'RETRY';
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 7, , 8]);
                        _e.label = 2;
                    case 2:
                        rejectedMessage = void 0;
                        if (lastAttemptStatus === 'REJECTED') {
                            rejectedMessage = RejectedMessage;
                        }
                        return [4 /*yield*/, this.requestPrompt(lastAttemptPrompt, rejectedMessage)];
                    case 3:
                        promptResult = _e.sent();
                        prompt = promptResult.prompt;
                        return [4 /*yield*/, this.generationAttempt(prompt, promptResult.scribble)];
                    case 4:
                        generationAttemptResult = _e.sent();
                        lastAttemptStatus = generationAttemptResult.status;
                        lastAttemptPrompt = prompt;
                        this.triesLeft--;
                        _e.label = 5;
                    case 5:
                        if (generationAttemptResult.status !== 'OK' && this.triesLeft > 0 && !this.turn.lostStatus) return [3 /*break*/, 2];
                        _e.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        _d = _e.sent();
                        this.turn.setLostTurn();
                        return [3 /*break*/, 8];
                    case 8:
                        if (((_b = generationAttemptResult === null || generationAttemptResult === void 0 ? void 0 : generationAttemptResult.status) !== null && _b !== void 0 ? _b : 'NOK') !== 'OK' && this.triesLeft <= 0) {
                            this.turn.setLostTurn();
                        }
                        url = (_c = generationAttemptResult === null || generationAttemptResult === void 0 ? void 0 : generationAttemptResult.imageUrl) !== null && _c !== void 0 ? _c : '';
                        if (this.turn.lostStatus) {
                            prompt = this.lostTurnPrompt;
                            url = LostTurnImageUrl;
                        }
                        return [4 /*yield*/, this.consolidatePlayerSubmissions(prompt !== null && prompt !== void 0 ? prompt : '', url)];
                    case 9:
                        phaseResult = _e.sent();
                        return [2 /*return*/, phaseResult];
                }
            });
        });
    };
    ImageGenerationPhase.prototype.generationAttempt = function (prompt, scribble) {
        var _a, _b, _c;
        return __awaiter(this, void 0, Promise, function () {
            var generationResponse, selectionIndex;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        (_a = this.timer) === null || _a === void 0 ? void 0 : _a.pause();
                        return [4 /*yield*/, this.imageGenerator.generateImages(prompt, this.model, scribble)];
                    case 1:
                        generationResponse = _d.sent();
                        (_b = this.timer) === null || _b === void 0 ? void 0 : _b.addDuration(ImageSelectionBonusSeconds);
                        (_c = this.timer) === null || _c === void 0 ? void 0 : _c.start();
                        if (generationResponse.status === 'NOK') {
                            return [2 /*return*/, { status: 'REJECTED', imageUrl: '' }];
                        }
                        return [4 /*yield*/, this.requestSelection(generationResponse.imageUrls)];
                    case 2:
                        selectionIndex = _d.sent();
                        if (selectionIndex < 0) {
                            return [2 /*return*/, { status: 'RETRY', imageUrl: '' }];
                        }
                        return [2 /*return*/, { status: 'OK', imageUrl: generationResponse.imageUrls[selectionIndex] }];
                }
            });
        });
    };
    ImageGenerationPhase.prototype.requestPrompt = function (promptStartPoint, rejectedMessage) {
        return __awaiter(this, void 0, Promise, function () {
            var promptPromise;
            var _this = this;
            return __generator(this, function (_a) {
                promptPromise = new Promise(function (resolve, reject) {
                    var _a, _b;
                    var resolver = ((_b = (_a = _this.timer) === null || _a === void 0 ? void 0 : _a.decoratePromise(_this.turn, resolve, reject)) !== null && _b !== void 0 ? _b : { resolver: resolve }).resolver;
                    var triesLeftMessage = _this.triesLeft > 1 ? "".concat(_this.triesLeft, " tries left") : 'Last try, don\'t be lame';
                    _this.componentSetter(function () { return (_jsx(PromptRequestView, { controlNet: _this.model === 'Control Net', theme: _this.phaseMessage, triesLeft: _this.triesLeft, promptStartPoint: promptStartPoint, rejectedMessage: rejectedMessage, timer: _this.timer, onComplete: resolver }, _this.triesLeft)); });
                });
                return [2 /*return*/, promptPromise];
            });
        });
    };
    ImageGenerationPhase.prototype.requestSelection = function (imageUrls) {
        return __awaiter(this, void 0, Promise, function () {
            var selectionPromise, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                var _a, _b;
                                var resolver = ((_b = (_a = _this.timer) === null || _a === void 0 ? void 0 : _a.decoratePromise(_this.turn, resolve, reject)) !== null && _b !== void 0 ? _b : { resolver: resolve }).resolver;
                                _this.componentSetter(function () { return (_jsxs(_Fragment, { children: [_this.timer && _jsx(TimerView, { timer: _this.timer }), _jsx("h1", { children: _this.phaseMessage }), _jsx(SelectionRequester, { onComplete: resolver, children: imageUrls.map(function (imageUrl, index) {
                                                return (_jsx("img", { src: imageUrl }, index));
                                            }) }), _this.triesLeft > 1 && _jsx("button", { onClick: reject, children: 'Retry (you were lame)' })] })); });
                            })];
                    case 1:
                        selectionPromise = _a.sent();
                        return [2 /*return*/, selectionPromise];
                    case 2:
                        e_1 = _a.sent();
                        return [2 /*return*/, -1];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ImageGenerationPhase.prototype.consolidatePlayerSubmissions = function (prompt, imageUrl) {
        return __awaiter(this, void 0, Promise, function () {
            var imageUrlSubmissions, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.componentSetter(function () { return _jsx(WaitingForOthers, {}); });
                        return [4 /*yield*/, this.groupSubmission.submit({ prompt: prompt, imageUrl: imageUrl })];
                    case 1:
                        imageUrlSubmissions = _a.sent();
                        result = imageUrlSubmissions.map(function (_a) {
                            var playerId = _a.submitterId, value = _a.value;
                            return ({ playerId: playerId, prompt: value.prompt, imageUrl: value.imageUrl });
                        });
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return ImageGenerationPhase;
}());
export { ImageGenerationPhase };
