var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var GameType;
(function (GameType) {
    GameType[GameType["ImageGame"] = 0] = "ImageGame";
    GameType[GameType["PretenderGame"] = 1] = "PretenderGame";
})(GameType || (GameType = {}));
var OPEN_AI_IMAGE_MODELS = ['Dalle 2'];
var REPLICATE_IMAGE_MODELS = ['Realistic Vision', 'Kandinsky', 'Control Net'];
var STABILITY_IMAGE_MODELS = ['Stable Diffusion SDXL 1.0', 'Stable Diffusion 2.1', 'Stable Diffusion 1.5',];
export var ALL_IMAGE_MODELS_ENUMERABLE = __spreadArray(__spreadArray(__spreadArray([], OPEN_AI_IMAGE_MODELS, true), REPLICATE_IMAGE_MODELS, true), STABILITY_IMAGE_MODELS, true);
