import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useEffect } from 'react';
function ExpandingTextArea(_a) {
    var value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder, className = _a.className;
    var textareaRef = useRef(null);
    useEffect(function () {
        var textarea = textareaRef.current;
        if (textarea) {
            var style = getComputedStyle(textarea);
            var isBorderBox = style.boxSizing === 'border-box';
            // TODO Line height is not taken into account, use library like JQuery to just get outerHeight
            var paddingTotal = parseFloat(style.paddingTop || '0') + parseFloat(style.paddingBottom || '0');
            var borderTotal = parseFloat(style.borderTopWidth || '0') + parseFloat(style.borderBottomWidth || '0');
            textarea.style.height = 'auto';
            var newHeight = textarea.scrollHeight + paddingTotal;
            if (isBorderBox) {
                newHeight += borderTotal;
            }
            textarea.style.height = "".concat(newHeight, "px");
        }
    }, [value]);
    return (_jsx("textarea", { ref: textareaRef, value: value, onChange: onChange, placeholder: placeholder, className: "expanding-textarea ".concat(className !== null && className !== void 0 ? className : '') }));
}
export default ExpandingTextArea;
