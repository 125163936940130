import { jsx as _jsx } from "react/jsx-runtime";
import '../../App.css';
import React, { useState, useContext, useEffect } from 'react';
import { SenderReceiverContext } from '../../Network/SenderReceiverContext';
import { PretenderGame } from './PretenderGame';
export function Pretenders() {
    var senderReceiver = useContext(SenderReceiverContext);
    var _a = useState(function () { return _jsx("div", { children: "bleep bloop BLEEP bloop bloop BLeep blooP BLOOOP" }); }), phaseComponent = _a[0], setPhaseComponent = _a[1];
    useEffect(function () {
        var pretenderGame = new PretenderGame(senderReceiver, setPhaseComponent);
        void pretenderGame.run();
    }, [senderReceiver]);
    return (_jsx("div", { children: phaseComponent }));
}
export default Pretenders;
