import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
var rootElement = document.getElementById('root');
if (!rootElement) {
    throw new Error('Could not find root element');
}
var root = ReactDOM.createRoot(rootElement);
root.render(
// <React.StrictMode> // TODO HACK Interferes wiht GroupSubmissions when mixed with shuffled teams (PretenderGame); different team order each run leads to different ProposedSubmitters
_jsx(BrowserRouter, { children: _jsx(App, {}) }));
