import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
var voteRevealDelay = 1000;
var finalTransitionDelay = 7000;
export function UpdateScoresScreen(_a) {
    var theme = _a.theme, imageGenerations = _a.imageGenerations, dreamScore = _a.dreamScore, votes = _a.votes, nameGetter = _a.nameGetter, onComplete = _a.onComplete;
    var _b = useState(false), showTotalScores = _b[0], setShowTotalScores = _b[1];
    var _c = useState([]), revealedVotes = _c[0], setRevealedVotes = _c[1];
    var _d = useState(undefined), intervalId = _d[0], setIntervalId = _d[1];
    useEffect(function () {
        var tick = function () {
            setRevealedVotes(function (previousRevealedVotes) { return votes.slice(0, previousRevealedVotes.length + 1); });
        };
        var id = setInterval(tick, voteRevealDelay);
        setIntervalId(id);
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    useEffect(function () {
        if (revealedVotes.length >= votes.length) {
            clearInterval(intervalId);
            dreamScore.updateScores(votes);
            setShowTotalScores(true);
            setTimeout(onComplete, finalTransitionDelay);
        }
    }, [revealedVotes]);
    return (_jsxs("div", { children: [_jsx("h1", { children: theme }), imageGenerations.map(function (imageGeneration, index) {
                var prompterId = imageGeneration.playerId;
                var prompterName = nameGetter(prompterId);
                var prompterRoundScore = revealedVotes.filter(function (vote) { return vote.voteForId === prompterId; })
                    .reduce(function (roundScore, vote) { return roundScore + dreamScore.getScoreToAdd(vote); }, 0);
                var prompterLikedBy = revealedVotes.filter(function (vote) { return vote.voteForId === prompterId; })
                    .map(function (vote) { return ({
                    name: nameGetter(vote.voterId),
                    id: vote.voterId,
                }); });
                return (_jsxs("div", { children: [_jsx("img", { src: imageGeneration.imageUrl, alt: "".concat(prompterName, "'s image") }), _jsx("p", { children: imageGeneration.prompt }), _jsxs("p", { children: ["Prompter: ", prompterName, "  ---  Round Score: ", prompterRoundScore] }), _jsx("p", { children: "Liked by: " }), _jsx("ul", { children: prompterLikedBy.map(function (liker, index) {
                                var likerColor = liker.id === prompterId ? 'red' : 'green';
                                return (_jsx("li", { style: { color: likerColor }, children: liker.name }, index));
                            }) })] }, index));
            }), showTotalScores && (_jsxs(_Fragment, { children: [_jsx("h2", { children: "New Total Scores:" }), _jsx("p", { children: dreamScore.getScores().map(function (_a) {
                            var playerId = _a.playerId, score = _a.score;
                            return "".concat(nameGetter(playerId), ": ").concat(score);
                        }).join(', ') })] }))] }));
}
