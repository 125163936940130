import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import ExpandingTextArea from '../Utils/Components/ExpandingTextArea';
import LoadingIcon from '../Assets/Gifs/LoadingIcon.gif';
export function StringRequester(_a) {
    var message = _a.message, onComplete = _a.onComplete, _b = _a.maxCharacters, maxCharacters = _b === void 0 ? 1000 : _b, _c = _a.warningDuration, warningDuration = _c === void 0 ? 2000 : _c, startingValue = _a.startingValue;
    var _d = useState(startingValue !== null && startingValue !== void 0 ? startingValue : ''), text = _d[0], setText = _d[1];
    var _e = useState(false), submitted = _e[0], setSubmitted = _e[1];
    var _f = useState(false), showCharacterLimitWarning = _f[0], setShowCharacterLimitWarning = _f[1];
    // Use useRef to store the timeout ID because it doesn't cause re-renders.
    var warningTimeout = React.useRef();
    function handleSubmit(e) {
        e.preventDefault();
        if (!text || text.length === 0) {
            return;
        }
        setSubmitted(true);
        onComplete(text);
    }
    function handleTextChange(e) {
        var newValue = e.target.value;
        if (newValue.length <= maxCharacters) {
            setText(newValue);
        }
        else {
            setShowCharacterLimitWarning(true);
            if (warningTimeout.current) {
                clearTimeout(warningTimeout.current);
            }
            warningTimeout.current = window.setTimeout(function () {
                setShowCharacterLimitWarning(false);
            }, warningDuration);
        }
    }
    useEffect(function () { return function () {
        if (warningTimeout.current) {
            clearTimeout(warningTimeout.current);
        }
    }; }, []);
    return (_jsxs("div", { children: [_jsx("h1", { children: message }), _jsxs("form", { onSubmit: handleSubmit, children: [_jsx(ExpandingTextArea, { onChange: handleTextChange, value: text, placeholder: 'Let\'s get on with it, what do you want me to generate?' }), submitted ? _jsx("img", { src: LoadingIcon, alt: 'bleep bloop...' })
                        : _jsx("button", { type: 'submit', children: 'Submit (please don\'t be lame)' }), showCharacterLimitWarning && (_jsxs("div", { style: { color: 'red' }, children: ["You have exceeded the character limit of ", maxCharacters, " characters."] }))] })] }));
}
