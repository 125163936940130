import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { SelectableChild, SelectableComponent } from '../Utils/Components/SelectableComponent';
export function SelectionRequester(_a) {
    var children = _a.children, onComplete = _a.onComplete;
    var _b = useState(-1), selectedItem = _b[0], setSelectedItem = _b[1];
    function handleSubmit(e) {
        e.preventDefault();
        if (selectedItem === -1) {
            return;
        }
        onComplete(selectedItem);
    }
    var selectableChildren = children === null || children === void 0 ? void 0 : children.map(function (child, index) { return (_jsx(SelectableChild, { selectionKey: index, children: child }, index.toString())); });
    return (_jsx("div", { children: _jsxs("form", { onSubmit: handleSubmit, children: [_jsx(SelectableComponent, { onSelect: setSelectedItem, children: selectableChildren }), _jsx("button", { disabled: selectedItem === -1, type: 'submit', children: "Make irreversible life decision" })] }) }));
}
