import { GameType } from "./Types";
var SharedGameMetadata = /** @class */ (function () {
    function SharedGameMetadata() {
    }
    SharedGameMetadata.get = function (gameType) {
        switch (gameType) {
            case GameType.ImageGame:
                return { gameType: gameType, minPlayers: 1, maxPlayers: 10 };
            case GameType.PretenderGame:
                return { gameType: gameType, minPlayers: 3, maxPlayers: 16 };
        }
    };
    return SharedGameMetadata;
}());
export { SharedGameMetadata };
