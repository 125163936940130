import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
function TypewriterText(_a) {
    var children = _a.children, _b = _a.delay, delay = _b === void 0 ? 10 : _b;
    var _c = useState(0), revealedLetters = _c[0], setRevealedLetters = _c[1];
    var _d = useState(undefined), intervalId = _d[0], setIntervalId = _d[1];
    useEffect(function () {
        var tick = function () {
            setRevealedLetters(function (previousRevealedLetters) { return previousRevealedLetters + 1; });
        };
        var id = setInterval(tick, delay);
        setIntervalId(id);
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    useEffect(function () {
        if (revealedLetters === children.length) {
            clearInterval(intervalId);
        }
    }, [revealedLetters]);
    return _jsx(_Fragment, { children: children.substring(0, revealedLetters) });
}
export default TypewriterText;
