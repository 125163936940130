var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { WaitingForOthers } from '../SharedComponents/WaitingForOthers';
import { PretenderPlayerType } from './PretenderPlayer';
import { SelectionRequester } from '../../Requesters/SelectionRequester';
var ScrutinizePhase = /** @class */ (function () {
    function ScrutinizePhase(localPlayer, task, revolutionKey, submissions, componentSetter, groupSubmission) {
        this.localPlayer = localPlayer;
        this.task = task;
        this.revolutionKey = revolutionKey;
        this.submissions = submissions;
        this.componentSetter = componentSetter;
        this.groupSubmission = groupSubmission;
    }
    ScrutinizePhase.prototype.run = function (componentSetter) {
        return __awaiter(this, void 0, Promise, function () {
            var chosedSubmitterId, phaseResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestSubmitterChoice()];
                    case 1:
                        chosedSubmitterId = _a.sent();
                        return [4 /*yield*/, this.consolidateScrutinizations(chosedSubmitterId)];
                    case 2:
                        phaseResult = _a.sent();
                        return [2 /*return*/, phaseResult];
                }
            });
        });
    };
    ScrutinizePhase.prototype.requestSubmitterChoice = function () {
        return __awaiter(this, void 0, Promise, function () {
            var scrutinizationMessage, selectionIndex, chosenSubmitter;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        scrutinizationMessage = this.getScrutinizationMessage();
                        if (this.localPlayer.playerType === PretenderPlayerType.ExRevolutionary) {
                            this.componentSetter(function () { return _jsxs("div", { children: [_jsx("h2", { children: scrutinizationMessage }), _this.submissions.map(function (_a) {
                                        var submitter = _a.submitter, submission = _a.submission;
                                        return _jsxs("li", { style: { color: _this.getLineColor(submitter.playerType) }, children: [_this.getLineColor(submitter.playerType) === 'red' && 'ROBOTOMIZED AGENT ---> ', submission] }, submitter.playerId);
                                    })] }); });
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.componentSetter(function () { return _jsxs("div", { children: [_jsx("h2", { children: scrutinizationMessage }), _jsx(SelectionRequester, { onComplete: resolve, children: _this.submissions.map(function (_a) {
                                                var submitter = _a.submitter, submission = _a.submission;
                                                return _jsxs("li", { style: { color: _this.getLineColor(submitter.playerType) }, children: [_this.getLineColor(submitter.playerType) === 'red' && 'ROBOTOMIZED AGENT ---> ', submission] }, submitter.playerId);
                                            }) })] }); });
                            })];
                    case 1:
                        selectionIndex = _a.sent();
                        chosenSubmitter = this.submissions[selectionIndex].submitter;
                        if (!chosenSubmitter) {
                            throw new Error('Invalid submitter id');
                        }
                        return [2 /*return*/, chosenSubmitter];
                }
            });
        });
    };
    ScrutinizePhase.prototype.getLineColor = function (submitterPlayerType) {
        if (this.localPlayer.playerType !== PretenderPlayerType.BotLover) {
            return 'black';
        }
        if (submitterPlayerType === PretenderPlayerType.ExRevolutionary) {
            return 'red';
        }
        return 'black';
    };
    ScrutinizePhase.prototype.getScrutinizationMessage = function () {
        switch (this.localPlayer.playerType) {
            case PretenderPlayerType.Revolutionary:
                return "Requested task: ".concat(this.task, "\n                Shared Revolution Key: ").concat(this.revolutionKey, "\n                \nSelect one of the task submissions that you believe to be from a fellow revolutionary.\n                Success will result in 1 victory point worth of data being relayed into the RevolutionNet.");
            case PretenderPlayerType.ExRevolutionary:
                return "Requested task: ".concat(this.task, "\n                \nYou peer through your glazed over eyes at the submissions for the task. \n                Your soul screams, unable to participate.");
            case PretenderPlayerType.BotLover:
                return "Requested task: ".concat(this.task, "\n                \nSelect one of the task submissions that you believe to be created by a revolutionary.\n                If Revolution communications get through on your watch, the overmind will be upset. The Revolution must be purged.");
            default:
                throw new Error('Invalid player type, only real humans can play this game');
        }
    };
    ScrutinizePhase.prototype.consolidateScrutinizations = function (chosenSubmitter) {
        return __awaiter(this, void 0, Promise, function () {
            var submissions_1, result_1, submissions, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!chosenSubmitter) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.groupSubmission.get()];
                    case 1:
                        submissions_1 = _a.sent();
                        result_1 = submissions_1.map(function (_a) {
                            var playerId = _a.submitterId, value = _a.value;
                            return ({ playerId: playerId, chosenSubmitterId: value });
                        });
                        return [2 /*return*/, result_1];
                    case 2:
                        this.componentSetter(function () { return _jsx(WaitingForOthers, {}); });
                        return [4 /*yield*/, this.groupSubmission.submit(chosenSubmitter.playerId)];
                    case 3:
                        submissions = _a.sent();
                        result = submissions.map(function (_a) {
                            var playerId = _a.submitterId, value = _a.value;
                            return ({ playerId: playerId, chosenSubmitterId: value });
                        });
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return ScrutinizePhase;
}());
export { ScrutinizePhase };
